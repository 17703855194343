.custom-table-header th {
    color: white !important; /* Color del texto */
    text-align: center; /* Centrar texto */
    font-size: 1.2rem; /* Tamaño más grande */
}

.custom-table-header th {
  font-size: 1.0rem; /* Tamaño más grande */
  padding: 12px; /* Agregar algo de relleno para que se vea mejor */
}

/* billing.module.scss */
.card-as-bill {
    position: relative; /* Esto es necesario para el posicionamiento del borde cortado */
    padding-bottom: 40px; /* Deja suficiente espacio para el borde cortado */
  }
  
  .card-as-bill .paper-cut {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px; /* Altura del borde cortado */
    background-color: #e8ebf1; /* Color de fondo de la parte cortada */
    background: -webkit-linear-gradient(#FFFFFF 0%, transparent 0%), -webkit-linear-gradient(135deg, #e8ebf1 33.33%, transparent 33.33%) 0 0%, #e8ebf1 -webkit-linear-gradient(45deg, #e8ebf1 33.33%, #FFFFFF 33.33%) 0 0%;
    background-repeat: repeat-x;
    background-size: 0px 100%, 14px 27px, 14px 27px;
}
  
.avatar-xxl {
    width: 3rem;
    height: 10rem;
    line-height: 5rem;
    font-size: 2rem;
}
