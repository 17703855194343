.wide-modal .modal-dialog {
    max-width: 100%;
  }
  
  .custom-view-modal-width {
    border-radius: 8px; /* Opcional: ajusta el radio del borde */
    max-width: 80% !important; 
    width: 80%; 
  }
  .custom-modal-width {
    border-radius: 8px; /* Opcional: ajusta el radio del borde */
    max-width: 80% !important; 
    width: 70%; 
  }
  